import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { Anchor, Item, Label } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import UsersTable from "../../components/tables/UsersTable";
import PageLayout from "../../layouts/PageLayout";

import data from "../../data/master/deposits.json";
import TransactionsTable from "../../components/tables/TransactionsTable";
import DepositTable from "../../components/tables/DepositTable";
import { Box, Text, Button } from "../../components/elements";
import { LabelField, LabelTextarea } from "../../components/fields";
import useSession from "../../hooks/useSession";
import {
  Flex,
  ListItem,
  OrderedList,
  Radio,
  RadioGroup,
  Skeleton
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  createRequestService,
  getMyAccounts,
  getMyLiveDemoAccounts,
  getPaymentMethodsService
} from "../../services/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useShowToast } from "../../hooks/useShowToast";
import QRCode from "react-qr-code";
import {
  getSettingsValueService,
  getUserBalanceService
} from "../../services/admin.service";
import VerifyOtpRequestModal from "../../components/VerifyOtpRequestModal";
import { TransactionFlowEnum } from "../../config/enum";

const typeOptions = [
  {
    value: "WITHDRAW",
    title: "DEPOSIT TO"
  },
  {
    value: "DEPOSIT",
    title: "WITHDRAW FROM"
  }
];

export default function UserWallet() {
  const [user, isLoading, setUser] = useSession();

  return (
    <PageLayout
      title="Wallet"
      breadcrumb={[
        {
          title: "Home",
          path: "/dashboard"
        },
        {
          title: "Wallet"
        }
      ]}
    >
      {user ? <DeposistsContainer user={user} /> : null}
    </PageLayout>
  );
}

const DeposistsContainer = ({ user }) => {
  const liveDemoAccountsQuery = useQuery(["liveAccBal", user], () =>
    getMyLiveDemoAccounts(user?.id)
  );

  const paymentsModeQuery = useQuery(["paymentModes", user], () =>
    getPaymentMethodsService()
  );

  const settingsQuery = useQuery(["settings"], () => getSettingsValueService());

  //getUserBalanceService
  const myWalletBalance = useQuery(["myWalletBalance"], () =>
    getUserBalanceService(user?.id)
  );

  const [medium, setMedium] = useState(null);

  const settings =
    _.find(settingsQuery?.data?.data?.data?.setting?.data, (_s) => {
      return _s?._tag === "MINIMUM_WITHDRAWAL_AMOUNT" ? true : false;
    })?._value || null;

  const walletBalance = myWalletBalance?.data?.data?.data?.walletBalance ?? 0;

  if (
    liveDemoAccountsQuery?.isLoading ||
    paymentsModeQuery?.isLoading ||
    myWalletBalance?.isLoading
  ) {
    return (
      <>
        <Skeleton minHeight="450px" />
      </>
    );
  }

  let accounts = liveDemoAccountsQuery?.data?.data?.data?.accounts?.data ?? [];

  accounts = _.filter(
    accounts ?? [],
    (acc) => acc?.is_live && acc?.balance != null
  );

  return (
    <>
      <DepositForm
        method={medium}
        setMethod={setMedium}
        accounts={accounts ?? []}
        minimumWithdrawalAnt={settings ? parseInt(settings) : 20}
        walletBalance={walletBalance}
      />
    </>
  );
};

const depositSchema = Yup.object().shape({
  amount: Yup.number().min(1).required("Amount is required"),
  account: Yup.string().required("Select an account")
});

const DepositForm = ({
  method,
  setMethod,
  accounts,
  minimumWithdrawalAnt,
  walletBalance
}) => {
  const [user] = useSession();
  const showToast = useShowToast();
  const imageUploadref = useRef();
  const [screenShot, setScreenShot] = useState(null);
  const [request, setRequest] = useState();

  const formik = useFormik({
    initialValues: {
      amount: 0,
      account: accounts?.length ? accounts[0]?.id : undefined,
      type: "WITHDRAW",
      transaction_flow: TransactionFlowEnum.WALLET_ACCOUNT
    },
    validationSchema: depositSchema,
    onSubmit: (values, actions) => {
      handleDeposit(values, actions);
    }
  });

  const getSelectedAccount = () => {
    const sAcc = _.find(accounts, [], (_ac) => {
      return _ac?.account_id === parseInt(formik.values?.account)
        ? true
        : false;
    });

    return sAcc;
  };

  useEffect(() => {
    if (formik?.values?.type === "DEPOSIT") {
      let sAcc = _.find(accounts, (_ac) => {
        return _ac?.id === parseInt(formik?.values?.account) ? true : false;
      });

      if (sAcc) {
        if (sAcc.balance < formik?.values?.amount) {
          formik.setFieldError(
            "account",
            "Account doesn't have sufficent balance"
          );
        }
      }
    }
  }, [formik?.values]);

  const handleDeposit = async (values, actions) => {
    if (values?.type === "WITHDRAW") {
      if (values?.amount > walletBalance) {
        actions.setFieldError(
          "amount",
          `Wallet doesn't have sufficient balance`
        );
        actions.setSubmitting(false);
        return;
      }
    } else {
      let sAcc = _.find(accounts, (_ac) => {
        return _ac?.id === parseInt(formik?.values?.account) ? true : false;
      });

      if (sAcc) {
        if (sAcc.balance < formik?.values?.amount) {
          formik.setFieldError(
            "account",
            "Account doesn't have sufficent balance"
          );
          actions.setSubmitting(false);
          return;
        }
      }
    }

    actions.setSubmitting(true);

    try {
      const result = await createRequestService(
        values?.amount,
        1,
        values?.account,
        "",
        values?.type,
        screenShot,
        TransactionFlowEnum.WALLET_ACCOUNT
      );
      if (result?.data?.success) {
        setRequest(result?.data?.data?.walletRequest);

        showToast("Success", "OTP Sent for confirmation");
        // actions?.resetForm()
        actions.setSubmitting(false);

        setScreenShot(null);
        if (imageUploadref?.current?.value) {
          imageUploadref.current.value = "";
        }

        return;
      }
    } catch (error) {}

    showToast("Error", "Something went wrong", "error");
    actions.setSubmitting(false);
  };

  return (
    <>
      <Row>
        <Col xl={6}>
          <Row>
            <form onSubmit={formik.handleSubmit}>
              <LabelField
                label="Wallet Balace"
                type="text"
                fieldSize="w-100 h-md"
                disabled={true}
                value={walletBalance ?? ""}
              />
              <br />

              <LabelField
                label="Select Type"
                name="type"
                option={typeOptions}
                value={formik?.values?.type}
                onBlur={formik?.handleBlur}
                onChange={formik?.handleChange}
                errorMessage={
                  formik?.errors?.type && formik?.touched?.type
                    ? formik?.errors?.type
                    : false
                }
              />
              <br />

              <Box
                style={{
                  display:
                    formik?.values?.transaction_flow ===
                    TransactionFlowEnum.DIRECT_WALLET
                      ? "none"
                      : "block"
                }}
              >
                <LabelField
                  label="Select Account"
                  fieldSize="w-100 h-md"
                  name={"account"}
                  option={[
                    { title: "Select Account", value: null },
                    ..._.map(accounts, (_acc) => {
                      return {
                        title: `${_acc?.account_id}  (Balance: $${
                          _acc?.balance ?? 0
                        }) ${_acc?.group === "IB\\VIP" ? "(IB ACCOUNT)" : ""}`,
                        value: _acc?.id
                      };
                    })
                  ]}
                  value={formik?.values.account}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  errorMessage={
                    formik?.errors?.account && formik?.touched?.account
                      ? formik?.errors?.account
                      : false
                  }
                />
                {getSelectedAccount() ? (
                  <div style={{ fontSize: "12px" }}>
                    Current Balance is {getSelectedAccount()?.balance}
                  </div>
                ) : (
                  ""
                )}
                <br />
              </Box>

              <Box>
                <LabelField
                  label="Amount"
                  type="number"
                  fieldSize="w-100 h-md"
                  name={"amount"}
                  value={formik?.values.amount}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  errorMessage={
                    formik?.errors?.amount && formik?.touched?.amount
                      ? formik?.errors?.amount
                      : false
                  }
                />
                {formik?.values?.transaction_flow ==
                TransactionFlowEnum.DIRECT_WALLET ? (
                  <Box style={{ fontSize: "14px" }}>
                    Current Wallet Balance is: {walletBalance}
                  </Box>
                ) : null}
              </Box>

              <br />
              <button
                type="button"
                className="mc-btn primary"
                disabled={formik?.isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  formik.submitForm();
                }}
              >
                <div>
                  {formik?.isSubmitting ? <Spinner size="xs" /> : "Transfer"}
                </div>
              </button>
            </form>
          </Row>
        </Col>

        <Col xl={1} />

        <Col xl={5}>
          <b>Terms and conditions</b>
          <OrderedList>
            <ListItem>
              Deposit amount equal to the credit received will be credited into
              the Trading Account or E-Wallet.
            </ListItem>
            <ListItem>
              Withdrawal will be allowed in the same mode as deposit is made. .
              Subject to change: Depend on Exchange Charge.
            </ListItem>
            <ListItem>
              At the time of withdrawal its a sole responsibility of the client
              while providing the Wallet ID.
            </ListItem>
            <ListItem>
              <b>USDT</b> paid via <b>TRC20</b> network will only be accepted.
            </ListItem>
            <ListItem>
              {" "}
              You can scan this code to send us the payment.
              <b>{method?.details ?? ""}</b>
            </ListItem>
            <ListItem>
              Charge for the Deposit is <b>0.75%</b> per transaction (which may
              vary)
            </ListItem>
            <ListItem>
              Within 48 working hours withdrawal will reflect into your account
            </ListItem>
            <ListItem>
              Kindly Contact on support@orbitglobalfx.com for any assistance.
            </ListItem>
          </OrderedList>

          {/* <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={method?.details}
            viewBox={`0 0 256 256`}
          /> */}
        </Col>
      </Row>

      <VerifyOtpRequestModal
        request={request}
        setRequest={setRequest}
        resetForm={formik?.resetForm}
      />
    </>
  );
};
